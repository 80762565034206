import React, { useRef } from "react";

import CheckoutFormContainer from './CheckoutFormContainer';

import "../MainForm/MainForm.css";

const SimpleSubmit = (props) => {
  const { formData, onPageUpdate, currentScreen } = props;
  const headerRef = useRef(null);

  return (
    <div className='checkoutContainer'>
      <div className='checkoutForm'>
        <CheckoutFormContainer
          formData={formData}
          onPageUpdate={onPageUpdate}
          currentScreen={currentScreen}
          headerRef={headerRef}
        />
      </div>
    </div>
  );
};

export default SimpleSubmit;
