import React from "react";

const ExistingVisitPhoneCheck = (props) => {
  const {
    phoneError,
    onPhoneChanged
  } =  props;

  const phoneMask = (phone) => {
    return phone
      .replace(/\D/g, "")
      .replace(/^(\d)/, "($1")
      .replace(/^(\(\d{3})(\d)/, "$1) $2")
      .replace(/(\d{3})(\d{1,4})/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const handleChange = (e) => {
    e.target.value = phoneMask(e.target.value);
    const cleanedPhone = e.target.value.replace(/\D/g, '');
    onPhoneChanged(cleanedPhone);
  };

  return (
    <div className="innerSubRow">
      <div className="formFieldSmall checkoutField">Mobile phone number</div>
      <input
        name="phone"
        placeholder="Phone"
        type="tel"
        onChange={handleChange}
        className={
          phoneError ? "checkoutInput checkoutInputError" : "checkoutInput"
        }
      />
      {phoneError && <div className="checkoutError">{phoneError}</div>}
    </div>
  );
};

export default ExistingVisitPhoneCheck;
