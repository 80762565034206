import React, { useState, useEffect } from "react";

import { introPage } from "../DataFiles/standardText";

import "../MainForm/MainForm.css";
import ExistingVisitPhoneCheck from "./ExistingVisitPhoneCheck";
import { routerMap } from "../utils/router";
import { useVisit, useVisitDispatch } from "../context/visitContext";
import LoadingModal from "../SimpleSubmit/LoadingModal";

const IntroPage = (props) => {
  const { goToForm, formData, setFormData } = props;
  const visitData = useVisit();
  const { setVisitId, setValidatedPhoneNumber } = useVisitDispatch();

  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [errorMessage, setError] = useState("");

  const setChecked = () => {
    setIsConsentChecked(!isConsentChecked);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      consentsSigned: isConsentChecked,
    });
  }, [isConsentChecked]);

  const onPhoneChanged = (phone) => {
    setPhoneError("");
    setError("");
    setValidatedPhoneNumber(phone);
  }

  const validateAndLookupVisit = async () => {
    setIsValidating(true);
    await routerMap.verifyPhone(visitData.phone, setPhoneError, async (isValidated) => {
      setIsValidating(false);
      if (isValidated) {
        const res = await routerMap.lookupVisitByPhone(visitData.phone, setError);
        setIsValidating(false);
        if (res) {
          setVisitId(res.visitId);
          setTimeout(() => {
            goToForm();
          });
        }
      }
    });
  };

  return (
    <div className="pageContainer">
      <div className="pageHeader">
        <div className="pageTitle"></div>
      </div>
      <div className="introContainer">
        <div className="introHeader">
          <div className="messageHeader hiThere">Welcome, Know Customers</div>
          <div className="introSubheader">
            <div>{introPage.header}</div>
            <div className="introFooter">
              <div>Please enter the phone number you used to register with Know.</div>
              <ExistingVisitPhoneCheck
                phoneError={phoneError}
                onPhoneChanged={onPhoneChanged}
              />
            </div>
            <div className="introFooter">
              <div>{introPage.footer}</div>
              <div>
                We respect your data and privacy. Click{" "}
                <a
                  href="https://customerconsents.s3.amazonaws.com/Beluga_Health_PA_Privacy_Policy.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  here
                </a>{" "}
                to learn more.
              </div>
            </div>
          </div>
          <div className="introConsents">
            <input
              type="checkbox"
              name="consents"
              onClick={setChecked}
              className="checkBox"
            />
            <span>
              I agree to the{" "}
              <a
                href="https://customerconsents.s3.amazonaws.com/Beluga_Health_Telemedicine_Informed_Consent.pdf"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Terms & Conditions
              </a>
            </span>
          </div>
          <button
            className="orderButton getStartedButton"
            onClick={validateAndLookupVisit}
            disabled={!isConsentChecked || visitData.phone.length !== 10}
          >
            Get Started
          </button>
          <div className="introFooter checkoutError">{errorMessage}</div>
        </div>
      </div>
      <div>{introPage.knowFooter}</div>
      <LoadingModal show={isValidating} />
    </div>
  );
};

export default IntroPage;
