import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { checkoutText } from '../DataFiles/standardText';
import { routerMap } from '../utils/router';

import '../MainForm/MainForm.css';
import PhotoIdForm from './PhotoIdForm';
import DemographicForm from './DemographicForm';
import LoadingModal from './LoadingModal';
import TurnstileWidget from '../Turnstile/TurnstileWidget';
import { useVisit } from '../context/visitContext';

const CheckoutFormContainer = (props) => {
  const {
    formData,
    onPageUpdate,
    currentScreen,
    headerRef
  } = props;
  const visitData = useVisit();
  const [turnstileToken, setTurnstileToken] = useState('');
  const handleTurnstileToken = (token) => {
    setTurnstileToken(token);
  };
  const [photosState, setPhotosState] = useState([]);
  const [error, setError] = useState('');
  const [isPendingData, setIsPendingData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [demographicData, setDemographicData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    pharmacyId: '',
  });

  const {
    submitPhoto,
    updateVisit,
    // validateTurnstile
  } = routerMap;

  const navigate = useNavigate();

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
    return 'Are you sure you want to leave this page, your information is still submitting?';
  };

  const handleCreateVisit = async () => {
    try {
      window.addEventListener('beforeunload', handleBeforeUnload);
      setIsPendingData(true);
      const visitId = visitData.visitId;
      const isSuccess = await updateVisit(
        visitId,
        formData,
        demographicData,
        setError,
        turnstileToken
      );

      if (!isSuccess) {
        setIsPendingData(false);
        window.removeEventListener('beforeunload', handleBeforeUnload);
        return;
      } else {
        await submitPhoto(visitId, photosState, setError, navigate);
      }

    } catch (error) {
      console.error('handleCreateVisit error', error);
    }
    setIsPendingData(false);
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };

  const updatePage = () => {
    onPageUpdate('submit');
  };

  const isPhotoScreen = () => {
    return currentScreen === 'photo';
  };

  const isSubmitScreen = () => {
    const isSubmit = currentScreen === 'submit';
    return isSubmit;
  };

  const validPhotos = photosState.length && photosState.every(p => p.photo.length)

  return (
    <div className="checkoutFormContainer">
      {isSubmitScreen() &&
        <>
          <div
            ref={headerRef}
            className='messageHeader'
            dangerouslySetInnerHTML={{ __html: checkoutText.header }}
          />
          <div className='messageBody'>{checkoutText.body}</div>
        </>
      }
      {isPhotoScreen() && (
        <PhotoIdForm photos={photosState} setPhotos={setPhotosState} />
      )}
      {isSubmitScreen() && (
        <DemographicForm
          headerRef={headerRef}
          formData={formData}
          setValidated={setValidated}
          onFormValidated={(data) => {
            setDemographicData(data);
          }}
        />
      )}

      {error && <div className='checkoutError'>{error}</div>}
      <div className={`${isPhotoScreen() ? 'photo' : ''} ccContainer`}>
        {isSubmitScreen() ? (
          <>
            <TurnstileWidget onTokenChange={handleTurnstileToken} />
            <button
              onClick={handleCreateVisit}
              className='orderButton checkoutButton'
              disabled={!validPhotos || !validated || isPendingData}
            >
              Submit your information
            </button>
          </>
        ) : (
          <button
            onClick={updatePage}
            className='orderButton checkoutButton'
            disabled={!validPhotos}
          >
            Continue
          </button>
        )}
      </div>
      <LoadingModal show={isPendingData} />
    </div>
  );
};

export default CheckoutFormContainer;
