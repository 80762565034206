import { createContext, useContext, useReducer } from "react";
// import { routerMap } from '../../../utils/router';

export const ACTIONS = {
  SET_VALIDATED_PHONE: "SET_VALIDATED_PHONE",
  UPDATE_FOUND_VISIT: "UPDATE_FOUND_VISIT",
};

const VisitContext = createContext(null);
const VisitDispatchContext = createContext(null);

const initialVisitState = {
  phone: "",
  visitId: "",
};
export function VisitProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialVisitState);
  const setValidatedPhoneNumber = (phone) => {
    dispatch({
      type: ACTIONS.SET_VALIDATED_PHONE,
      payload: phone,
    });
  };

  const setVisitId = (visitId) => {
    dispatch({
      type: ACTIONS.UPDATE_FOUND_VISIT,
      payload: visitId,
    });
  };

  return (
    <VisitContext.Provider value={state}>
      <VisitDispatchContext.Provider
        value={{ dispatch, setValidatedPhoneNumber, setVisitId }}
      >
        {children}
      </VisitDispatchContext.Provider>
    </VisitContext.Provider>
  );
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_VALIDATED_PHONE: {
      return {
        ...state,
        phone: action.payload,
      };
    }
    case ACTIONS.UPDATE_FOUND_VISIT: {
      return {
        ...state,
        visitId: action.payload,
      };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export function useVisit() {
  return useContext(VisitContext);
}

export function useVisitDispatch() {
  return useContext(VisitDispatchContext);
}
