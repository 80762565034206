import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API } from 'aws-amplify';
import { Modal } from 'react-bootstrap';

import { dataSecure } from '../DataFiles/standardText';
import { routerMap } from '../utils/router';
import { apiKey } from '../config';


import '../MainForm/MainForm.css';
import {
  stateNameToAbbreviationMap,
  statesFullNameList,
} from '../DataFiles/states';
import xIcon from '../Assets/x@2x.png';
import SearchIcon from '../Assets/search_icon.png';
import { useVisit } from '../context/visitContext';

const DemographicForm = (props) => {
  const { onFormValidated, setValidated, headerRef } = props;

  const [localState, setLocalState] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    pharmacyId: '',
  });
  const visitData = useVisit();
  const [zipLength, setZipLength] = useState(0);
  const [phoneError, setPhoneError] = useState('');
  const [zipError, setZipError] = useState('');
  const [ageError, setAgeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isPhoneValidated, setIsPhoneValidated] = useState(false);
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);
  const [pharmacyList, setPharmacyList] = useState([]);
  const [isPharmacyLoading, setIsPharmacyLoading] = useState(false);
  const [pharmName, setPharmName] = useState('');
  const [openCustomPharm, setOpenCustomPharm] = useState(false);
  const [selectedPharm, setSelectedPharm] = useState({
    name: '',
    value: null,
    address: '',
    city: '',
    state: '',
  });

  const { verifyPhone } = routerMap;

  const handleCloseModal = () => {
    setOpenCustomPharm(false);
  };

  const handlePharmChange = (e) => {
    setPharmName(e.target.value);
  };

  const handlePharmSelect = (e) => {
    e.preventDefault();
    const foundPharm = pharmacyList.find(
      (pharm) => pharm.PharmacyId.toString() === e.currentTarget.value.toString()
    );

    setSelectedPharm({
      name: foundPharm.StoreName,
      value: foundPharm.PharmacyId,
      city: foundPharm.City,
      state: foundPharm.State,
      address: foundPharm.Address1,
    });
    setLocalState({
      ...localState,
      pharmacyId: e.currentTarget.value,
    });
  };

  const handleChoosePharm = () => {
    setOpenCustomPharm(!openCustomPharm);
  };

  const getPharmacies = async () => {
    try {
      setIsPharmacyLoading(true);
      const pharmResponse = await API.post('brains', '/external/pharmacies', {
        credentials: 'include',
        body: {
          zip: localState.zip,
          name: pharmName,
          apiKey,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (pharmResponse.status !== 200) {
        console.error(pharmResponse);
      }
      setPharmacyList(pharmResponse.data);
      setIsPharmacyLoading(false);
    } catch (err) {
      console.log('pharmacy error', err);
    }
  };

  const staticPharm = () => {
    if (selectedPharm.name.length) {
      return (
        <div className='pharmSelectedText'>
          <div className='pharmName'>{selectedPharm.name}</div>
          <div>{selectedPharm.address && selectedPharm.address}</div>
          {selectedPharm.city && (
            <div>
              {selectedPharm.city}, {selectedPharm.state}
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const formattedPharmacies = pharmacyList.map((pharmacy, idx) => {
    const buttonStyle =
      selectedPharm.value === pharmacy.PharmacyId
        ? 'pharmacySelect formRadioLine formRadioLineSelected'
        : 'pharmacySelect formRadioLine';
    return (
      <button
        className={buttonStyle}
        onClick={handlePharmSelect}
        value={pharmacy.PharmacyId}
        name={pharmacy.StoreName}
        id={pharmacy.PharmacyId}
        key={`${idx}`}
      >
        <div className='formRadioCircle'>
          {selectedPharm.value === pharmacy.PharmacyId && (
            <div className='formRadioInnerCircle' />
          )}
        </div>
        <div className='pharmText'>
          <div className='pharmName'>{pharmacy.StoreName}</div>
          <div>{pharmacy.Address1}</div>
          <div>
            {pharmacy.City}, {pharmacy.State}
          </div>
        </div>
      </button>
    );
  });

  const phoneMask = (phone) => {
    return phone
      .replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{3})(\d)/, '$1) $2')
      .replace(/(\d{3})(\d{1,4})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  };

  const handleChange = (e) => {
    const now = new Date();
    const localDob = localState.dob ? localState.dob.split('/') : [1, 1, now.getFullYear()];
    switch (e.target.name) {
      case 'zip':
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        setZipLength(e.target.value.length);
        break;
      case 'phone':
        e.target.value = phoneMask(e.target.value);
        const cleanedPhone = e.target.value.replace(/\D/g, '');
        setLocalState({
          ...localState,
          [e.target.name]: cleanedPhone,
        });
        setIsPhoneValidated(false);
        break;
      case 'dobMonth':
        setLocalState({
          ...localState,
          dob: `${e.target.value}/${localDob[1]}/${localDob[2]}`,
        });
        break;
      case 'dobDate':
        setLocalState({
          ...localState,
          dob: `${localDob[0]}/${e.target.value}/${localDob[2]}`,
        });
        break;
      case 'dobYear':
        setLocalState({
          ...localState,
          dob: `${localDob[0]}/${localDob[1]}/${e.target.value}`,
        });
        break;
      default:
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const validatePhone = useCallback(async () => {
    setIsValidatingPhone(true);
    setPhoneError("");
    if (localState.phone.length === 10) {
      if (localState.phone !== visitData.phone) {
        setPhoneError("Phone number mismatch!");
        setIsValidatingPhone(false);
        return;
      }
      await verifyPhone(localState.phone, setPhoneError, setIsPhoneValidated);
      setIsValidatingPhone(false);
    } else {
      setPhoneError('Please enter your cell phone number');
      setIsPhoneValidated(false);
      setIsValidatingPhone(false);
      return;
    }
  }, [localState.phone, verifyPhone, visitData.phone]);

  const handleAgeBlur = () => {
    if(localState.dob.length < 8) {
      setAgeError(
        'Date of birth must be in valid format'
      );
      return;
    }
    ageCheck();
  };

  const ageCheck = () => {
    const now = new Date();
    const eighteenYearsAgo = new Date(
      now.getFullYear() - 18,
      now.getMonth(),
      now.getDate()
    );
    const tooOld = new Date(
      now.getFullYear() - 110,
      now.getMonth(),
      now.getDate()
    );
    const dobArray = localState.dob ? localState.dob.split('/') : [1, 1, now.getFullYear()];
    const dobMonth = dobArray[0];
    const dobDate = dobArray[1];
    const dobYear = dobArray[2];
    const dob = new Date(dobYear, dobMonth - 1, dobDate);
    if (dob > eighteenYearsAgo) {
      setAgeError(
        'This service is only available for people over the age of 18.'
      );
      setValidated(false);
      return false;
    } else if (dob < tooOld) {
      setAgeError(
        'Please enter a valid date of birth'
      );
      setValidated(false);
      return false;
    } else {
      setAgeError('');
      return true;
    }
  };

  const handleZipBlur = () => {
    if (zipLength < 5) {
      setZipError('Please enter a valid zip code');
    } else {
      setZipError('');
    }
  };

  const handleEmailBlur = () => {
    const regex = /[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+/;
    const regMatch = regex.test(localState.email);
    if (!regMatch) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  useEffect(() => {
    if (
      localState.phone.length === 10 &&
      !isValidatingPhone &&
      !isPhoneValidated
    ) {
      validatePhone();
    }
  }, [
    localState.phone,
    validatePhone,
    isPhoneValidated,
    isValidatingPhone,
  ]);

  useEffect(() => {
    if (
      localState.firstName.length &&
      localState.lastName.length &&
      localState.email.length &&
      localState.phone.length &&
      localState.address.length &&
      localState.city.length &&
      localState.state.length &&
      localState.zip.length &&
      localState.dob.length >= 8 &&
      localState.pharmacyId.length &&
      !ageError &&
      !zipError &&
      !phoneError &&
      !emailError &&
      isPhoneValidated
    ) {
      setValidated(true);
      onFormValidated(localState);
    } else {
      setValidated(false);
    }
  }, [
    localState,
    ageError,
    phoneError,
    zipError,
    emailError,
    onFormValidated,
    isPhoneValidated,
    setValidated,
  ]);

  useEffect(() => {
    headerRef.current.scrollIntoView();
  }, [])

  return (
    <div className='demographicContainer'>
      <div className='checkoutForm'>
        <div>
          <div className='dataSecureText'>
            {dataSecure.header} For more information, click{' '}
            <a
              href='https://customerconsents.s3.amazonaws.com/Beluga_Health_PA_Privacy_Policy.pdf'
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>
            .
          </div>
        </div>

        <div className='demographicSectionLabel'>Personal Information</div>

        <div className='demographicSubRow'>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>First name</div>
            <input
              name='firstName'
              placeholder='First Name'
              onChange={handleChange}
              className='checkoutInput'
            />
          </div>
          <div className='spacer' />
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>Last name</div>
            <input
              name='lastName'
              placeholder='Last Name'
              onChange={handleChange}
              className='checkoutInput'
            />
          </div>
        </div>
        <div className='demographicSubRow emailAndPhone'>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>Email</div>
            <input
              name='email'
              placeholder='Email'
              onChange={handleChange}
              onBlur={handleEmailBlur}
              className={
                emailError
                  ? 'checkoutInput checkoutInputError'
                  : 'checkoutInput'
              }
            />
            {emailError && <div className='checkoutError'>{emailError}</div>}
          </div>
          <div className='spacer' />
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>
              Mobile phone number
            </div>
            <input
              name='phone'
              placeholder='Phone'
              type='tel'
              onChange={handleChange}
              // maxLength='10'
              className={
                phoneError
                  ? 'checkoutInput checkoutInputError'
                  : 'checkoutInput'
              }
            />
            {phoneError && <div className='checkoutError'>{phoneError}</div>}
          </div>
        </div>
        <div className='demographicSubRow dobAndAddress'>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>Date of birth</div>
            <div className='dobInputs'>
              <input
                name='dobMonth'
                placeholder='MM'
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? 'checkoutInputMini checkoutInputError'
                    : 'checkoutInputMini'
                }
                maxLength='2'
              />
              <span className='expirySlash'>/</span>
              <input
                name='dobDate'
                placeholder='DD'
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? 'checkoutInputMini checkoutInputError'
                    : 'checkoutInputMini'
                }
                maxLength='2'
              />
              <span className='expirySlash'>/</span>
              <input
                name='dobYear'
                placeholder='YYYY'
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? 'checkoutInputShort checkoutInputError'
                    : 'checkoutInputShort'
                }
                maxLength='4'
              />
            </div>
            {ageError && <div className='checkoutError'>{ageError}</div>}
          </div>
          <div className='spacer' />
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>Address</div>
            <input
              name='address'
              placeholder='Address'
              onChange={handleChange}
              className='checkoutInput'
            />
          </div>
        </div>
        <div className='demographicSubRow addressRow'>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>City</div>
            <input
              name='city'
              placeholder='City'
              onChange={handleChange}
              className='checkoutInput'
            />
          </div>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>State</div>
            <div className='checkoutInputShort state'>
              <label>
                <select
                  name='state'
                  id='state-select'
                  onChange={handleChange}
                  required
                  defaultValue=''
                >
                  <option value='' disabled>
                    State
                  </option>
                  {statesFullNameList.map((name) => {
                    return (
                      <Fragment key={name}>
                        <option
                          value={stateNameToAbbreviationMap[name]}
                        >
                          {name}
                        </option>
                      </Fragment>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>Zip Code</div>
            <input
              name='zip'
              placeholder='Zip'
              onChange={handleChange}
              onBlur={handleZipBlur}
              className={
                zipError
                  ? 'checkoutInputShort zip checkoutInputError'
                  : 'checkoutInputShort zip'
              }
              maxLength='5'
            />
            {zipError && <div className='checkoutError'>{zipError}</div>}
          </div>
        </div>

        <div className='demographicSubRow pharmacyRow'>
          <div className='innerSubRow'>
            <div>
              <div className='idVerifyTitle formFieldSmall checkoutField'>
                Pharmacy
              </div>
              {staticPharm()}
              <button onClick={handleChoosePharm} className='selectPharmButton'>
                {selectedPharm.name.length === 0
                  ? 'Select a pharmacy'
                  : 'Select a different pharmacy'}
              </button>
            </div>
          </div>
          <div className='innerSubRow'></div>
        </div>
      </div>

      <Modal
        show={openCustomPharm}
        onHide={handleCloseModal}
        dialogClassName='modalDims'
      >
        <div className='modalHeader'>
          <div className='modalTitle'>
            <div className='introSubheader'>Select a Pharmacy</div>
            <button onClick={handleCloseModal} className='modalCloseButton'>
              <img src={xIcon} className='xIcon' alt='close' />
            </button>
          </div>
        </div>
        <div className='modalBody'>
          <div className='demographicContainer'>
            <div className='demographicSubRow'>
              <div className='innerSubRow'>
                <div>
                  <div className='formFieldSmall checkoutField'>
                    Pharmacy Name
                  </div>
                  <input
                    name='pharmName'
                    placeholder='Enter a name'
                    onChange={handlePharmChange}
                    id='searchPharmInput'
                    className='heckoutInput'
                  />
                </div>
              </div>
              <div className='innerSubRow'>
                {pharmName.length > 0 && (
                  <button
                    onClick={getPharmacies}
                    className='orderButton findPharmButton'
                  >
                    <img
                      src={SearchIcon}
                      alt='search pharmacy'
                      className='searchIcon'
                    />
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className='pharmResults'>
            {isPharmacyLoading ? (
              <div className='loader'></div>
            ) : pharmacyList.length > 0 ? (
              <>
                <div className='formFieldSmall checkoutField'>
                  Select a pharmacy in your area. If none of these look
                  familiar, please check your zip code and try again.
                </div>
                <div className='pharmacyListContainer'>
                  {formattedPharmacies}
                </div>
              </>
            ) : (
              <div className='pharmacyStatusText'>No Pharmacy Found</div>
            )}
          </div>
        </div>

        <div className='modalFooter'>
          <button
            className='modalCancelButton orderButton'
            onClick={handleCloseModal}
          >
            Cancel
          </button>

          {selectedPharm.value && (
            <button
              className='modalSaveButton orderButton'
              onClick={handleCloseModal}
            >
              Save
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default DemographicForm;
